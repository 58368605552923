body {
    background: #EAEAEA none repeat scroll 0% 0%;;
    /*background: #000000 none repeat scroll 0% 0%;;*/
}

.navbar-inverse .navbar-nav > li > a, .navbar-inverse .navbar-text {
    color: #f2f2f2;
    font-weight: 400;
    /*font-size: 12px;*/
}

/*.navbar-inverse .navbar-nav > li > a, .navbar-inverse .navbar-text {*/
    /*font-weight: 400;*/
    /*font-size: 14px;*/
/*}*/

/*a:hover, a:active, a:focus {*/
    /*background-color: #FFFFFF;*/
    /*color: #000000;*/
/*}*/