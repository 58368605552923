@charset('UTF-8');
/*@viewport{*/
    /*zoom: 1.0;*/
    /*width: device-width;*/
/*}*/

/*@viewport{*/
    /*zoom: 1.0;*/
    /*width: extend-to-zoom;*/
/*}*/
/*@-ms-viewport{*/
    /*width: extend-to-zoom;*/
    /*zoom: 1.0;*/
/*}*/

/* ----------- iPad mini ----------- */

/*
================================================
Portrait priority but supports some of Landscape
================================================
*/
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
/*and (-webkit-min-device-pixel-ratio: 2) {*/
{
    /*
    ==========================
     base styles
    ===========================
     */

    body {
        margin-top: 61px;
        /*font-size: 10px;*/
    }

    /*
    ==========================
     navbar
    ===========================
     */

    .navbar-fixed-top .navbar-brand {
        font-size: 150%;
    }

    .navbar-fixed-top {
        padding: 0;
    }

    .navbar-nav {
        text-align: left;
        /*font-size: 75%;*/
        font-size: 100%;
    }

    /*
    =====================================
    jumbotron messages - i love jlscustom
    =====================================
    */

    #bghome .jumbotron {
        height: 795px;  /*480px - height of navbar 60px*/
        /*height: 882px; *//* 480px - height of navbar 60px*/
    }

    .font150 {
        font-size: 100%;
    }

    #wrapperhome {
        background-attachment: scroll;
    }

    /*
    ==========================
     ourservices
    ===========================
     */

    #ourservices > .container-fluid > .row > .col-sm-12 {
        height: 100%;
        padding-bottom: 20px;
    }

    #ourservices > .container-fluid > .row > .col-sm-12 > .row {
        padding-top: 0;
    }

    /*
    ==========================
     showcase
    ===========================
     */

    #showcase > .container-fluid > .row > .col-xs-12 {
        height: 100%;
    }

    #showcase > .container-fluid > .row > .col-xs-12 > .row {
        padding-top: 0;
    }

    #thumbs > .col-sm-6 {
        /*height: auto !important;*/
        height: 250px;
    }

    #thumbs .col-sm-6 .fa-stack {
    line-height: 1.75em;
    }

    #thumbs .col-sm-6 .fa-stack-2x {
        font-size: 1.75em;
    }

    /*
    ==========================
     colorbox
    ===========================
     */

    #cboxCurrent {
        /*color: #FFFFFF;*/
        margin-left:-150px;
        /*display: none;*/
    }

    #cboxTitle {
        color: #000;
    }

    /*
    ==========================
     about
    ===========================
     */

    #about > .container-fluid > .row > .col-sm-12 {
        height: 100%;
    }

    #about > .container-fluid > .row > .col-sm-12 {
        padding-top: 0;
    }

    #about > .container-fluid > .row > .col-sm-12 > .jumbotron {
        padding-top: 0;
        padding-bottom: 0;
    }

    /*
    ==========================
     enquire
    ===========================
     */

    #enquire > .container > .row > .col-smxs-12 {
        height: 100%;
    }

    #enquire > .container > .row > .col-sm-12 {
        padding-top: 0;
        padding-bottom: 20px;
    }


    /*
    ==========================
     enquire - success
    ===========================
     */

    #success {
        height: 200px;
    }

    /*
   ==========================
    footer
   ===========================
    */

    #footer {
        height: 100%;
        text-align: center;
    }

    #footer .list-inline {
        margin-left: 0;
    }

    #footer > .navbar > .small {
        font-size: 85%;
    }

    #footer .ul-block {
        text-align: left;
        margin-left: -50px;
    }

    #newsletter .form-control {
        width: 100%;
    }

    #credits {
        line-spacing: 1px;
    }

    /*
    =======================
    scroll top button class
    =======================
    */

    .scroll-top {
        height: 30px;
    }

    .scroll-top .btn {
        padding: 4px 2px;
    }

}

/*
================================================
Landscape only
================================================
*/
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (orientation: landscape) {

    /*
    ==========================
     base styles
    ===========================
     */
    /*
    ==========================
     navbar
    ===========================
     */
    /*
    =====================================
    jumbotron messages - i love jlscustom
    =====================================
    */
    #bghome .jumbotron {
        height: 626px; /* 664px before 768 - height of navbar 60px*/
    }

    #ourservices .col-sm-12 .panel {
        height: 335px;
        /*padding-bottom: 20px;*/
    }

    /*
    ==========================
     showcase
    ===========================
     */
    #thumbs > .col-sm-6 {
        height: 220px;
    }

    #thumbs .col-sm-6 .fa-stack {
    line-height: 1.75em;
    }

    #thumbs .col-sm-6 .fa-stack-2x {
        font-size: 1.75em;
    }

    /*
    ==========================
     enquire - success
    ===========================
     */

    #success {
        height: 210px;
    }

    /*
   ==========================
    footer
   ===========================
    */
    #newsletter .form-control {
        width: 100%;
    }

    /*
    =======================
    scroll top button class
    =======================
    */

}

