#nav.navbar {
    height: 61px;
}

#nav.navbar-default ul.nav.navbar-nav, #nav.navbar-inverse ul.nav.navbar-nav {
    /*font-size: 85%;*/
    margin: 15px;
}

#nav .navbar-brand {
    padding: 5px 15px;
}

.navbar-default, .navbar-inverse {
    background: #000000;
}

#navhome.navbar-inverse .navbar-nav .active > a,
#navhome.navbar-inverse .navbar-nav .active > a:hover,
#navhome.navbar-inverse .navbar-nav .active > a:focus,
#navhome.navbar-inverse .navbar-nav li:not(.socials) > a:hover,
#navhome.navbar-inverse .navbar-nav li > a:focus {
    color: #000000;
    background-color: #FFFFFF;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    background-image: none;
    box-shadow: none;
    text-shadow: none;
}

.navbar-inverse .navbar-nav > li > a {
    text-shadow: none;
}

.navbar-toggle {
    padding: 19px 10px;
}

.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
    border-color: #FFFFFF;
}

.navbar-collapse {
    /*border-top: none;*/
}

#navsocials .socials > li > a:hover, a:focus {
    background: transparent;
}


