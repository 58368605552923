@charset('UTF-8');

/*----------- iPhone 6+ -----------*/

/*
================================================
Portrait priority but supports some of Landscape
================================================
*/
@media only screen
and (min-width: 414px)
and (max-width: 736px)
{
    /*
    ==========================
     base styles
    ===========================
     */

    body {
        margin-top: 60px;
    }

    /*
    ==========================
     navbar
    ===========================
     */

    .navbar-fixed-top .navbar-brand {
        font-size: 175%;
    }

    .navbar-fixed-top {
        padding: 0;
    }

    #navhome .navbar-nav {
        margin: 0 -15px;
        text-align: center;
        font-size: 100%;
    }

    /*
    =====================================
    jumbotron messages - i love jlscustom
    =====================================
    */

    #bghome .jumbotron {
        height: 736px; /* 480px - height of navbar 70px*/
    }

    #wrapperhome {
        background-attachment: scroll;
    }

    /*
    ==========================
     ourservices
    ===========================
     */

    #ourservices > .container-fluid > .row > .col-xs-12 {
        height: 100%;
        padding-bottom: 20px;
    }

    #ourservices > .container-fluid > .row > .col-xs-12 > .row {
        padding-top: 0;
    }

    /*
    ==========================
     showcase
    ===========================
     */

    #showcase > .container-fluid > .row > .col-xs-12 {
        height: 100%;
    }

    #showcase > .container-fluid > .row > .col-xs-12 > .row {
        padding-top: 0;
    }

    #thumbs > .col-xs-12 {
        /*height: auto !important;*/
        height: 300px;
    }

    /*
    ==========================
     colorbox
    ===========================
     */

    #cboxCurrent {
        /*color: #FFFFFF;*/
        margin-left:-150px;
        /*display: none;*/
    }

    #cboxTitle {
        color: #000;
    }

    /*
    ==========================
     about
    ===========================
     */

    #about > .container-fluid > .row > .col-xs-12 {
        height: 100%;
    }

    #about > .container-fluid > .row > .col-xs-12 {
        padding-top: 0;
    }

    #about > .container-fluid > .row > .col-xs-12 > .jumbotron {
        padding-top: 0;
        padding-bottom: 0;
    }

    /*
    ==========================
     enquire
    ===========================
     */

    #enquire > .container > .row > .col-xs-12 {
        height: 100%;
    }

    #enquire > .container > .row > .col-xs-12 {
        padding-top: 0;
    }

    #contactusform > .form-group > .col-xs-12 > .pull-right {
        float: none !important;
    }

    /*
   ==========================
    footer
   ===========================
    */

    #footer {
        height: 100%;
    }

    #footer > .navbar > .small {
        font-size: 85%;
    }

    #footer .ul-block {
        text-align: center;
        margin-left: -40px;
    }

    #legal {
        margin-top: 10px;
    }

    #credits {
        line-spacing: 1px;
    }

    /*
    =======================
    scroll top button class
    =======================
    */

    .scroll-top {
        height: 30px;
    }

    .scroll-top .btn {
        padding: 4px 2px;
    }
}

/*
================================================
Landscape only
================================================
*/
@media only screen
and (min-width: 414px)
and (max-width: 736px)
and (orientation: landscape) {
    /*
    =====================================
    jumbotron messages - i love jlscustom
    =====================================
    */

    #bghome .jumbotron {
        height: 284px; /* 480px - height of navbar 70px*/
    }


    /*
    ===============
    #quotelines div
    ===============
    */

    /*
    ==========================
     ourservices
    ===========================
     */

    #services > .col-xs-2 {
        padding-left: 50px;
    }

    /*
    ==========================
     showcase
    ===========================
     */

    #thumbs > .col-xs-12 {
        /*height: auto !important;*/
        height: 400px;
    }

    /*
    ==========================
     colorbox
    ===========================
     */


    /*
    ==========================
     about
    ===========================
     */


    /*
    ==========================
     enquire
    ===========================
     */


    /*
    ==========================
     enquire - success
    ===========================
     */


    /*
   ==========================
    footer
   ===========================
    */



    /*
    =======================
    scroll top button class
    =======================
    */


}


/*----------- iPhone 6 -----------*/

/*
================================================
Portrait priority but supports some of Landscape
================================================
*/
@media only screen
and (min-width: 375px)
and (max-width: 667px)
/*and (-webkit-min-device-pixel-ratio: 2) {*/
{
    /*
    ==========================
     base styles
    ===========================
     */

    body {
        margin-top: 60px;
    }

    /*
    ==========================
     navbar
    ===========================
     */

    .navbar-fixed-top .navbar-brand {
        font-size: 175%;
    }

    .navbar-fixed-top {
        padding: 0;
    }

    #navhome .navbar-nav {
        margin: 0 -15px;
        text-align: center;
        font-size: 100%;
    }

    /*
    =====================================
    jumbotron messages - i love jlscustom
    =====================================
    */

    #bghomejumbo {
        height: 607px; /* 480px - height of navbar 70px*/
    }

    .font150 {
        font-size: 100%;
    }

    #wrapperhome {
        background-attachment: scroll;
    }

    /*
    ===============
    #quotelines div
    ===============
    */
    #quoteLines .jumbotron {
        padding-bottom: 0;
    }

    /*
    ==========================
     ourservices
    ===========================
     */

    #ourservices > .container-fluid > .row > .col-xs-12 {
        height: 100%;
        padding-bottom: 20px;
    }

    #ourservices > .container-fluid > .row > .col-xs-12 > .row {
        padding-top: 0;
    }

    #ourcustommade {
        height: 100%;
        padding-top: 0;
    }

    #showcaseenquirebutton {
        padding-top: 40px;
    }

    #showcaseenquirebutton > .col-xs-6 > .row {
        text-align: center;
    }

    #showcasebutton, #enquirebutton {
        height: 60px;
        z-index: 1;
    }

    #services.font150 {
        font-size: 75%;
    }

    /*
    ==========================
     showcase
    ===========================
     */

    #showcase > .container-fluid > .row > .col-xs-12 {
        height: 100%;
    }

    #showcase > .container-fluid > .row > .col-xs-12 > .row {
        padding-top: 0;
    }

    /*#showcase img {*/
    /*filter: alpha(opacity=100);*/
    /*IE*/
    /*-moz-opacity-: 1.0;*/
    /*Mozilla*/
    /*opacity: 1.0;*/
    /*width: 100%;*/
    /*height: 100%;*/
    /*transition: all 0.3s ease;*/
    /*-moz-transition: all 0.3s ease;*/
    /*-webkit-trasition: all 0.3s ease;*/
    /*-o-trasition: all 0.3s ease;*/
    /*-ms-trasition: all 0.3s ease;*/
    /*}*/

    /*#showcase img:hover {*/
        /*filter: alpha(opacity=100);*/
        /*IE*/
        /*-moz-opacity-: 1.0;*/
        /*Mozilla*/
        /*opacity: 1.0;*/
        /*width: 100%;*/
        /*height: 100%;*/
        /*box-shadow: inset 0 100px 0 0 #c1557a;*/
        /*box-shadow: inset 0 0 10px 0 #ccc;*/
        /*-moz-box-shadow: inset 0 0 10px 0 #ccc;*/
        /*-webkit-box-shadow: inset 0 0 10px 0 #ccc;*/
        /*transition: all 0.3s ease;*/
        /*-moz-transition: all 0.3s ease;*/
        /*-webkit-trasition: all 0.3s ease;*/
        /*-o-trasition: all 0.3s ease;*/
        /*-ms-trasition: all 0.3s ease;*/
    /*}*/

    #thumbs > .col-xs-12 {
        /*height: auto !important;*/
        height: 220px;
    }

    /*
    ==========================
     colorbox
    ===========================
     */

    #cboxCurrent {
        /*color: #FFFFFF;*/
        margin-left:-150px;
        /*display: none;*/
    }

    #cboxTitle {
        color: #000;
    }

    /*
    ==========================
     about
    ===========================
     */

    #about > .container-fluid > .row > .col-xs-12 {
        height: 100%;
    }

    #about > .container-fluid > .row > .col-xs-12 {
        padding-top: 0;
    }

    #about > .container-fluid > .row > .col-xs-12 > .jumbotron {
        padding-top: 0;
        padding-bottom: 0;
    }

    /*
    ==========================
     enquire
    ===========================
     */

    #enquire > .container > .row > .col-xs-12 {
        height: 100%;
    }

    #enquire > .container > .row > .col-xs-12 {
        padding-top: 0;
    }

    #contactusform > .form-group > .col-xs-12 > .pull-right {
        float: none !important;
    }

    /*
    ==========================
     enquire - success
    ===========================
     */


    /*
   ==========================
    footer
   ===========================
    */

    #footer {
        height: 100%;
    }

    #footer > .navbar > .small {
        font-size: 85%;
    }

    #footer .ul-block {
        text-align: center;
        margin-left: -40px;
    }

    #legal {
        margin-top: 10px;
    }

    #credits {
        line-spacing: 1px;
    }

    /*
    =======================
    scroll top button class
    =======================
    */

    .scroll-top {
        height: 30px;
    }

    .scroll-top .btn {
        padding: 4px 2px;
    }
}

/*
================================================
landscape only
================================================
*/
@media only screen
and (min-width: 375px)
and (max-width: 667px)
/*and (-webkit-min-device-pixel-ratio: 2) {*/
and (orientation: landscape) {
    /*
    ==========================
     base styles
    ===========================
     */

    /*
    ==========================
     navbar
    ===========================
     */

    /*
    =====================================
    jumbotron messages - i love jlscustom
    =====================================
    */

    #bghomejumbo {
        height: 270px; /* 480px - height of navbar 70px*/
    }

    .v-align {
        margin: 8% auto;
    }

    /*
    ===============
    #quotelines div
    ===============
    */


    /*
    ==========================
     ourservices
    ===========================
     */

    #services > .col-xs-2 {
        padding-left: 20px;
    }

    /*
    ==========================
     showcase
    ===========================
     */

    #thumbs > .col-xs-12 {
        /*height: auto !important;*/
        height: 400px;
    }

    /*
    ==========================
     colorbox
    ===========================
     */

    /*
    ==========================
     about
    ===========================
     */

    /*
    ==========================
     enquire
    ===========================
     */


    /*
    ==========================
     enquire - success
    ===========================
     */

    /*
   ==========================
    footer
   ===========================
    */



    /*
    =======================
    scroll top button class
    =======================
    */

}

/*
===============
iPhone 4 and 4S
===============
*/

/*
================================================
Portrait as well as landscape
================================================
*/
@media only screen
and (min-width: 320px)
and (max-width: 480px)
{
    /*
    ==========================
     base styles
    ===========================
     */

    body {
        margin-top: 61px;
    }

    /*
    ==========================
     navbar
    ===========================
     */

    .navbar-fixed-top .navbar-brand {
         font-size: 175%;
     }

    .navbar-fixed-top {
        padding: 0;
    }

    #navhome .navbar-nav {
        margin: 0 -15px;
        /*text-align: left;*/
        font-size: 100%;
    }

    /*
    =====================================
    jumbotron messages - i love jlscustom
    =====================================
    */

    #bghome .jumbotron {
        height: 348px; /* 480px - height of navbar 61px*/
    }

    #bghome .jumbotron .btn {
        margin-bottom: 10px;
        padding: 4px 12px;
        font-size: 12px;
    }

    #bghome .jumbotron h2 {
        font-size: 150%;
    }

    #bghome .jumbotron .socials {
        font-size: 12px;
    }

    #caret {
        font-size: 75%;
    }

    #navsocials > li > a {
        padding: 0;
    }

    #wrapperhome {
        background-attachment: scroll;
    }

    /*
    ==========================
     ourservices
    ===========================
     */

    #ourservices > .container-fluid > .row > .col-xs-12 {
        height: 100%;
        padding-bottom: 10px;
    }

    #ourservices > .container-fluid > .row > .col-xs-12 > .row {
        padding-top: 0;
    }

    /*
    ==========================
     showcase
    ===========================
     */

    #showcase > .container-fluid > .row > .col-xs-12 {
        height: 100%;
    }

    #showcase > .container-fluid > .row > .col-xs-12 > .row {
        padding-top: 0;
    }

    #showcase img {
    filter: alpha(opacity=100);
    /*IE*/
    -moz-opacity-: 1.0;
    /*Mozilla*/
    opacity: 1.0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-trasition: all 0.3s ease;
    -o-trasition: all 0.3s ease;
    -ms-trasition: all 0.3s ease;
    }

    #showcase img:hover {
        filter: alpha(opacity=100);
        /*IE*/
        -moz-opacity-: 1.0;
        /*Mozilla*/
        opacity: 1.0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0 100px 0 0 #c1557a;
        box-shadow: inset 0 0 10px 0 #ccc;
        -moz-box-shadow: inset 0 0 10px 0 #ccc;
        -webkit-box-shadow: inset 0 0 10px 0 #ccc;
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-trasition: all 0.3s ease;
        -o-trasition: all 0.3s ease;
        -ms-trasition: all 0.3s ease;
    }

    #thumbs > .col-xs-12 {
        /*height: auto !important;*/
        height: 220px;
    }

    #thumbs .col-sm-6 .fa-stack {
    line-height: 1.75em;
    }

    #thumbs .col-sm-6 .fa-stack-2x {
        font-size: 1.75em;
    }

    /*
    ==========================
     colorbox
    ===========================
     */

    #cboxCurrent {
        /*color: #FFFFFF;*/
        margin-left:-150px;
        /*display: none;*/
    }

    #cboxTitle {
        color: #000;
    }

    /*
    ==========================
     about
    ===========================
     */

    #about > .container-fluid > .row > .col-xs-12 {
        height: 100%;
    }

    #about > .container-fluid > .row > .col-xs-12 {
        padding-top: 0;
    }

    #about > .container-fluid > .row > .col-xs-12 > .jumbotron {
        padding-top: 0;
        padding-bottom: 0;
    }

    /*
    ==========================
     enquire
    ===========================
     */

    #enquire > .container > .row > .col-xs-12 {
        height: 100%;
    }

    #enquire > .container > .row > .col-xs-12 {
        padding-top: 0;
    }

    #contactusform > .form-group > .col-xs-12 > .pull-right {
        float: none !important;
    }

    /*
    ==========================
     enquire - success
    ===========================
     */

    /*
   ==========================
    footer
   ===========================
    */

    #footer {
        height: 100%;
    }

    #footer > .navbar > .small {
        font-size: 85%;
    }

    #footer .ul-block {
       text-align: center;
       margin-left: -40px;
    }

    #legal {
       margin-top: 10px;
    }

    #credits {
        line-spacing: 1px;
    }

    /*
    =======================
    scroll top button class
    =======================
    */

    .scroll-top {
        height: 30px;
    }

    .scroll-top .btn {
        padding: 4px 2px;
    }

}


/*
======================
Landscape only
======================
*/
@media only screen
and (min-width: 320px)
and (max-width: 480px)
and (orientation: landscape) {

    /*
    ==========================
     navbar
    ===========================
     */

    .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
        max-height: 250px;
    }

    #navhome .navbar-nav {
        text-align: center;
    }

    /*
    =====================================
    jumbotron messages - i love jlscustom
    =====================================
    */

    #bghome .jumbotron {
        height: 188px; /* 480px - height of navbar 70px*/
    }

    /*
    ==========================
     showcase
    ===========================
     */

    #thumbs > .col-xs-12 {
        /*height: auto !important;*/
        height: 350px;
    }

    /*
    ==========================
     about
    ===========================
     */

    #about > .container-fluid > .row > .col-xs-12 {
        height: 260px;
    }

}


 /*----------- iPhone 5 and 5S -----------*/

/*
================================================
Portrait priority but supports some of Landscape
================================================
*/
@media only screen
and (min-width: 320px)
and (max-width: 568px)
and (device-aspect-ratio: 40/71)
{
    /*
    ==========================
     base styles
    ===========================
     */

    body {
        margin-top: 60px;
    }

    /*
    ==========================
     navbar
    ===========================
     */

    .navbar-fixed-top .navbar-brand {
        font-size: 175%;
    }

    .navbar-fixed-top {
        padding: 0;
    }

    #navhome .navbar-nav {
        margin: 0 -15px;
        text-align: center;
        font-size: 100%;
    }

    /*
    =====================================
    jumbotron messages - i love jlscustom
    =====================================
    */

    #bghome .jumbotron {
        height: 436px; /* 480px - height of navbar 70px*/
    }

    #bghome .jumbotron .btn {
        margin-bottom: 10px;
        padding: 4px 12px;
        font-size: 12px;
    }

    #bghome .jumbotron h2 {
        font-size: 150%;
    }

    #bghome .jumbotron .socials {
        font-size: 12px;
    }

    #wrapperhome {
        background-attachment: scroll;
    }

    /*
    ==========================
     ourservices
    ===========================
     */

    #ourservices > .container-fluid > .row > .col-xs-12 {
        height: 100%;
        padding-bottom: 20px;
    }

    #ourservices > .container-fluid > .row > .col-xs-12 > .row {
        padding-top: 0;
    }

    /*
    ==========================
     showcase
    ===========================
     */

    #showcase > .container-fluid > .row > .col-xs-12 {
        height: 100%;
    }

    #showcase > .container-fluid > .row > .col-xs-12 > .row {
        padding-top: 0;
    }

    #thumbs > .col-xs-12 {
        /*height: auto !important;*/
        height: 220px;
    }

    /*
    ==========================
     colorbox
    ===========================
     */

    #cboxCurrent {
        /*color: #FFFFFF;*/
        margin-left:-150px;
        /*display: none;*/
    }

    #cboxTitle {
        color: #000;
    }

    /*
    ==========================
     about
    ===========================
     */

    #about > .container-fluid > .row > .col-xs-12 {
        height: 100%;
    }

    #about > .container-fluid > .row > .col-xs-12 {
        padding-top: 0;
    }

    #about > .container-fluid > .row > .col-xs-12 > .jumbotron {
        padding-top: 0;
        padding-bottom: 0;
    }

    /*
    ==========================
     enquire
    ===========================
     */

    #enquire > .container > .row > .col-xs-12 {
        height: 100%;
    }

    #enquire > .container > .row > .col-xs-12 {
        padding-top: 0;
    }

    #contactusform > .form-group > .col-xs-12 > .pull-right {
        float: none !important;
    }

    /*
    ==========================
     enquire - success
    ===========================
     */


    /*
   ==========================
    footer
   ===========================
    */

    #footer {
        height: 100%;
    }

    #footer > .navbar > .small {
        font-size: 85%;
    }

    #footer .ul-block {
        text-align: center;
        margin-left: -40px;
    }

    #legal {
        margin-top: 10px;
    }

    #credits {
        line-spacing: 1px;
    }

    /*
    =======================
    scroll top button class
    =======================
    */

    .scroll-top {
        height: 30px;
    }

    .scroll-top .btn {
        padding: 4px 2px;
    }
}

/*
================================================
Landscape only
================================================
*/
@media only screen
and (min-width: 320px)
and (max-width: 568px)
and (device-aspect-ratio: 71/40)
and (orientation: landscape)
{

    /*
    ==========================
     base styles
    ===========================
     */

    body {
        margin-top: 60px;
    }

    /*
    ==========================
     navbar
    ===========================
     */

    .navbar-fixed-top .navbar-brand {
        font-size: 175%;
    }

    .navbar-fixed-top {
        padding: 0;
    }

    #navhome .navbar-nav {
        margin: 0 -15px;
        text-align: center;
        font-size: 100%;
    }

    /*
    =====================================
    jumbotron messages - i love jlscustom
    =====================================
    */

    #bghome .jumbotron {
        height: 196px; /* 480px - height of navbar 70px*/
    }

    #bghome .jumbotron .btn {
        margin-bottom: 10px;
        padding: 4px 12px;
        font-size: 12px;
    }

    #bghome .jumbotron h2 {
        font-size: 150%;
    }

    #bghome .jumbotron .socials {
        font-size: 12px;
    }

    #caret {
        font-size: 75%;
    }

    /*
    ==========================
     ourservices
    ===========================
     */

    #ourservices > .container-fluid > .row > .col-xs-12 {
        height: 100%;
        padding-bottom: 20px;
    }

    #ourservices > .container-fluid > .row > .col-xs-12 > .row {
        padding-top: 0;
    }

    /*
    ==========================
     showcase
    ===========================
     */

    #showcase > .container-fluid > .row > .col-xs-12 {
        height: 100%;
    }

    #showcase > .container-fluid > .row > .col-xs-12 > .row {
        padding-top: 0;
    }

    #thumbs > .col-xs-12 {
        /*height: auto !important;*/
        height: 350px;
    }

    /*
    ==========================
     colorbox
    ===========================
     */

    #cboxCurrent {
        /*color: #FFFFFF;*/
        margin-left:-150px;
        /*display: none;*/
    }

    #cboxTitle {
        color: #000;
    }

    /*
    ==========================
     about
    ===========================
     */

    #about > .container-fluid > .row > .col-xs-12 {
        height: 100%;
    }

    #about > .container-fluid > .row > .col-xs-12 {
        padding-top: 0;
    }

    #about > .container-fluid > .row > .col-xs-12 > .jumbotron {
        padding-top: 0;
        padding-bottom: 0;
    }

    /*
    ==========================
     enquire
    ===========================
     */

    #enquire > .container > .row > .col-xs-12 {
        height: 100%;
    }

    #enquire > .container > .row > .col-xs-12 {
        padding-top: 0;
    }

    #contactusform > .form-group > .col-xs-12 > .pull-right {
        float: none !important;
    }

    /*
    ==========================
     enquire - success
    ===========================
     */


    /*
   ==========================
    footer
   ===========================
    */

    #footer {
        height: 100%;
    }

    #footer > .navbar > .small {
        font-size: 85%;
    }

    #footer .ul-block {
        text-align: center;
        margin-left: -40px;
    }

    #legal {
        margin-top: 10px;
    }

    #credits {
        line-spacing: 1px;
    }

    /*
    =======================
    scroll top button class
    =======================
    */

    .scroll-top {
        height: 30px;
    }

    .scroll-top .btn {
        padding: 4px 2px;
    }

}







