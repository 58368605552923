body {
    /*background: #DFDFDF none repeat scroll 0% 0%;*/
    position: relative;
    display: block;
    height: 100%;
}

.panel-wrapper {
    min-height: 560px;
}

.panel {
    /*background: #fff;*/
    /*min-height: 560px;*/
    width: 100%;
    box-shadow: 0pt 2px 5px rgba(105, 108, 109, 0.7), 0px 0px 8px 5px rgba(208, 223, 226, 0.4) inset;
    border-radius: 5px;
    padding: 20px;
}

.panel-heading {
    padding: 5px;
}

.form-horizontal .control-label {
    margin-bottom: 5px;
    text-align: left;
}

.form-horizontal input:not([type="checkbox"]) {
    /*padding: 10px 5px 10px 26px;*/
    /*box-sizing: content-box;*/
    border-radius: 3px;
    box-shadow: 0px 1px 4px 0px rgba(168, 168, 168, 0.6) inset;
    transition: all 0.2s linear 0s;
    border: 1px solid #B2B2B2;
}

.form-horizontal input:active:not([type="checkbox"]), #wrapper input:focus:not([type="checkbox"]) {
    border: 1px solid #3D8B3D;
    box-shadow: 0px 1px 4px 0px rgba(168, 168, 168, 0.9) inset;
    outline-color: #3D8B3D;
}

.form-horizontal input:active:not([type="checkbox"]), .form-horizontal input:focus:not([type="checkbox"]) {
    border: 1px solid #3D8B3D;
    box-shadow: 0px 1px 4px 0px rgba(168, 168, 168, 0.9) inset;
    outline-color: #3D8B3D;
}

/*for fontaweosme inside input or svg*/

/*.form-horizontal input.icon {*/
    /*font-family:'FontAwesome', Lato;*/
/*}*/
/*.form-horizontal input.icon::-webkit-input-placeholder {*/
    /*font-family:'FontAwesome', Lato;*/
/*}*/
/*.form-horizontal input.icon::-moz-placeholder {*/
    /*font-family:'FontAwesome', Lato;*/
/*}*/
/*.form-horizontal input.icon::-ms-input-placeholder {*/
    /*font-family:'FontAwesome', Lato;*/
/*}*/
