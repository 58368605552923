@charset('UTF-8');
/*
============================================
Extends base theme theme/superhero.css
Stylesheet Name: local.css
Stylesheet URI: http://l8studio.com.au/css
Author: Simple Pages Team
Author URI: https://simplepages.com/
Description: This is a custom style sheet to accommodate customized CSS rules for break points & media queries.
Version: 1.0.0
*/
/*  =======================================
   	Table of Contents
   	=======================================
	#Typography
	#Links
	#Misc
	#Header

	=======================================
	Colour Palette
	=======================================
	#f2f4f1 = Soft Grey Background
	#26282d = Dark Charcoal Grey
	#444652 = Foster Grey
	#14cccc = Turquoise

	=======================================
	Media Queries
	=======================================
	#MQ1  - Mobile (Portrait & Landscape)
	#MQ2  - Mobile (Landscape)
	#MQ3  - Mobile (Portrait)
	#MQ4  - iPad   (Portrait & Landscape)
	#MQ5  - iPad   (Landscape)
	#MQ6  - iPad   (Portrait)
	#MQ7  - iPad & Desktop
	#MQ8  - Desktop & Laptops
	#MQ9  - Large Screens
	#MQ10 - iPhone 4 Retina
	#MQ11 - Print

/* Base CSS rules */
@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,100,300,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,300,300italic,400italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Sansita+One|Boogaloo|Carter+One|Lemon|Timmana);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,600,700,300,800);

html, body {
    margin: 0;
    padding: 0;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    /*letter-spacing: 1.5px;*/
}

body {
    /*font-weight: 100;*/
    /*background: #c9c9c9;*/
    height: 100%;
    margin-top: 61px;
    overflow: hidden;
    /*font-family: Montserrat, 'Lato', Roboto, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;*/
    /*font-family: Montserrat, Roboto, 'Lato', Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;*/
    /*font-family: Arial, Helvetica, Helvetica Neue, Montserrat, Roboto, 'Lato', Open sans, sans-serif;*/
    font-family: Open sans, Arial, Helvetica, Helvetica Neue, Montserrat, Roboto, 'Lato', sans-serif;
    /*font-family: 'Lato', Montserrat, Roboto, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;*/
    /*font-family: Roboto, Montserrat, 'Lato', Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;*/
    /*font-size: 12px;*/
    font-size: 14px;
    text-align: center;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 600;
}

/*overwrite CSS for links*/
a,
a:focus,
a:hover {
    text-decoration: none;
    outline: none;
    -webkit-transition: all 0.1s ease 0s;
       -moz-transition: all 0.1s ease 0s;
         -o-transition: all 0.1s ease 0s;
        -ms-transition: all 0.1s ease 0s;
            transition: all 0.1s ease 0s;
}

a {
    color: inherit;
}

:link:focus, :visited:focus {
    -moz-outline: none;
    outline: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

a {
    background: none repeat scroll 0% 0% transparent;
}



/*overwrite CSS for navbar*/

/*.navbar {*/
    /*background-image: linear-gradient(#191A1D, #3A3F44 100%, #191A1D);*/
    /*background-image: linear-gradient(rgba(43, 62, 80, 0.7), rgba(96, 143, 192, 0.7) 100%, rgb(25, 26, 29));*/
    /*background-image: linear-gradient(rgba(43, 62, 80, 0.7), rgba(43, 62, 80, 0.7) 100%, rgb(25, 26, 29));*/
    /*padding: 0;*/
/*}*/

#navhome.navbar-default ul.nav.navbar-nav, #navhome.navbar-inverse ul.nav.navbar-nav {
    /*font-size: 85%;*/
    margin: 32px;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-trasition: all 0.3s ease;
    -o-trasition: all 0.3s ease;
    -ms-trasition: all 0.3s ease;
}

#navhome.navbar-default .navbar-brand, #navhome.navbar-inverse .navbar-brand {
    margin-left: 0;
    height: auto;
}

#navhome.navbar-default, #navhome.navbar-inverse {
    border: 0 none;
    /*background-color: rgba(0, 0, 0, 0.9) ;*/
    /*background-color: rgba(0, 0, 0, 0.7) ;*/
    /*background-color: #363636;*/
    background-color: transparent;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-trasition: all 0.3s ease;
    -o-trasition: all 0.3s ease;
    -ms-trasition: all 0.3s ease;
}

#navhome.navbar-fixed-top .navbar-brand {
    font-size: 300%;
    padding: 0 15px;
}

#navhome.navbar-fixed-top {
    /*padding: 27.5px 0;*/
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-trasition: all 0.3s ease;
    -o-trasition: all 0.3s ease;
    -ms-trasition: all 0.3s ease;
}

.navbar-default .navbar-nav > li > a, .navbar-inverse .navbar-nav > li > a {
    /*text-transform: uppercase;*/
    /*font-size: 75%;*/
    /*padding-left: 25px;*/
    /*padding-right: 25px;*/
    /*line-height: 40px;*/
    padding: 4px 10px;
    /*color: #C2BFBF;*/
    /*color: #f2f2f2;*/
}

#navsocials > li > a {
    padding: 4px 4px;
}

.navbar-default .navbar-nav > li.socials > a:hover, .navbar-default .navbar-nav > li.socials > a:focus,
.navbar-inverse .navbar-nav > li.socials > a:hover, .navbar-inverse .navbar-nav > li.socials > a:focus {
    /*color: #FFCF00;*/
    /*color: #AE8913;*/
    /*color: #FFFFFF;*/
    /*color: #00FFE4;*/
    /*color: #5BC0DE;*/
    /*color: #FFFFFF;*/
    background-color: transparent;
}

#navhome.navbar-default .navbar-toggle, #navhome.navbar-inverse .navbar-toggle {
    /*border-color: #F0DB3C;*/
}

#navhome.navbar-default .navbar-toggle:hover, #navhome.navbar-inverse .navbar-toggle:focus {
    /*background-color: #fff;*/
}

.navbar #brandlogo {
    height: 100px;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-trasition: all 0.3s ease;
    -o-trasition: all 0.3s ease;
    -ms-trasition: all 0.3s ease;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    /*color: #FFCF00;*/
    /*color: #AE8913;*/
    /*color: #00FFE4;*/
    /*color: #5BC0DE;*/
    /*color: #FFFFFF;*/
    /*background-color: transparent;*/
    /*border-bottom: 2px solid #FFCF00;*/
    /*border-bottom: 2px solid #AE8913;*/
    border-radius: 0;
    /*border-bottom: 1px solid #00E4FF;*/
    /*border: 1px solid #FFFFFF;*/
    /*border: 1px solid #00FFE4;*/
    /*border: 1px solid #5BC0DE;*/
    /*border: 1px solid #FFFFFF;*/
}

/*.navbar-collapse .collapse {*/
   /*margin-top: -7.5px;*/
   /*margin-bottom: -7.5px;*/
/*}*/

/*navbar brand fontawesome icon size*/
/*header > .navbar > .container > .navbar-header > .navbar-brand > .fa {*/
    /*font-size: 175%;*/
/*}*/

header > .navbar > .container > .collapse > .nav > .page-scroll > a > .fa {
    font-size: 150%;
    padding-left: 5px;
}
/*.navbar-brand:hover, nav a:hover {*/
/*transform: translateY(-2px) ;*/
/*transition: all 0.1s ease-in-out;*/
/*-moz-transition: all 0.1s ease-in-out;*/
/*-webkit-trasition: all 0.1s ease-in-out;*/
/*-o-trasition: all 0.1s ease-in-out;*/
/*-ms-trasition: all 0.1s ease-in-out;*/
/*}*/

/* image transitions*/
/*#wow {*/
    /*margin-top: 70px;*/
    /*width: 75%;*/
    /*height: 75%;*/
/*}*/

/* DIVs */

#blackbg {
    background: #000000 no-repeat center center fixed ;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: -150;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
#wrapperhome {
    /*background: url('http://l8studio.app:8000/img/image01.jpg') no-repeat center center fixed ;*/
    /*background: url('http://l8studio.com.au/img/renders/fadedbg/image01.jpg') no-repeat center center fixed ;*/
    background: url(https://l8studio.com.au/img/renders/fadedbg/image01.jpg) no-repeat center center fixed ;
    content: "";
    filter: alpha(opacity=65);
    /*IE*/
    -moz-opacity-: 0.65;
    /*Mozilla*/
    opacity: 0.65;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: -100;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

/*Filter styles*/
.saturate {-webkit-filter: saturate(3);}
.grayscale {-webkit-filter: grayscale(100%);}
.contrast {-webkit-filter: contrast(160%);}
.brightness {-webkit-filter: brightness(0.25);}
.blur {-webkit-filter: blur(3px);}
.invert {-webkit-filter: invert(100%);}
.sepia {-webkit-filter: sepia(100%);}
.huerotate {-webkit-filter: hue-rotate(180deg);}
.rss.opacity {-webkit-filter: opacity(50%);}

/*#bghome .col-lg-12 {*/
        /*height: 676px; *//* 480px - height of navbar 70px*/
/*}*/

#bghome .jumbotron {
        height: 812px;  874px - height of navbar 61px
        /*height: 874px; *//* 874px - height of navbar 61px*/
}

#ourservices {
    /*background: url('/img/textures/binding_dark.png') repeat center center;*/
    /*background: url('/img/blurs/blur-2.png') repeat center center;*/
    /*z-index: 1 ;*/
}

#ourservices ul li {
    padding: 10px 0;
}

#ourservices > .container-fluid > .row > .col-lg-12 {
        height: 100%;
        padding-bottom: 20px;
    }

#ourservices > .container-fluid > .row > .col-md-12 {
        height: 100%;
        padding-bottom: 20px;
    }

/*#ourservices {*/
    /*background-color: #FFDB47;*/
    /*content: "";*/
    /*filter: alpha(opacity=100);*/
    /*IE*/
    /*-moz-opacity-: 1.0;*/
    /*Mozilla*/
    /*opacity: 1.0;*/
    /*left: 0;*/
    /*bottom: 0;*/
    /*right: 0;*/
    /*position: relative;*/
    /*position: fixed;*/
    /*z-index: -1;*/
    /*background: url('http://jlscustomapp.app:8000/img/textures/concrete-texture.jpg') repeat-y center center;*/
    /*background: url('http://jlscustomapp.app:8000/img/blurs/blur-2.png') repeat-y center center;*/
    /*background: url('http://jlscustomapp.app:8000/img/textures/binding_dark.png') repeat center center;*/
    /*background: url('http://jlscustomapp.app:8000/img/textures/Startseite-Bild-4_Küche-Bild1WEB.gif') no-repeat center center;*/
    /*-webkit-background-size: cover;*/
    /*-moz-background-size: cover;*/
    /*-o-background-size: cover;*/
    /*background-size: cover;*/
/*}*/

/*#about {*/
    /*margin-top: 70px;*/
    /*height: 400px;*/
    /*background: url('http://jlscustomapp.app:8000/img/textures/concrete-texture.jpg') no-repeat center center fixed;*/
    /*background-color: #272B30;*/
/*}*/
/*div#wrapperOpaque {*/
    /*margin-top: 70px;*/

/*}*/

/*div#showcase {*/

/*}*/

/*helper classes*/

.font150 {font-size: 150%;}

.marginlr225 {margin: auto 22.5%;}
.marginlr50 {margin: auto 50%;}
.margintb50 {margin: 50% auto;}
.marginlrtb50 {margin: 50%;}


.marginbottom40 {margin-bottom: 40px;}

.br1 { border-radius: 1px;}
.br2 { border-radius: 2px;}
.br3 { border-radius: 3px;}
.br4 { border-radius: 4px;}
.br5 { border-radius: 5px;}
.br6 { border-radius: 6px;}
.br7 { border-radius: 7px;}
.br8 { border-radius: 8px;}
.br9 { border-radius: 9px;}
.br10 { border-radius: 10px;}

.fw100 {
    font-weight: 100;
}
.fw200 {
    font-weight: 200;
}
.fw300 {
    font-weight: 300;
}
.fw400 {
    font-weight: 400;
}
.fw500 {
    font-weight: 500;
}

.opacity0 {
    filter: alpha(opacity=0);
    /*IE*/
    -moz-opacity-: 0;
    /*Mozilla*/
    opacity: 0;
    /*-webkit-transition: all 100ms ease;*/
    /*-moz-transition: all 100ms ease;*/
    /*-ms-transition: all 100ms ease;*/
    /*-o-transition: all 100ms ease;*/
    /*transition: all 100ms ease;*/
}

.opacity1 {
    filter: alpha(opacity=100);
    /*IE*/
    -moz-opacity-: 1.0;
    /*Mozilla*/
    opacity: 1.0;
}

.blur1 {
    /*background: url('http://l8studio.app:8000/img/blurs/Blur-3.png') no-repeat center center;*/
    /*background: url('http://l8studio.com.au/img/blurs/Blur-03.png') no-repeat center center;*/
    background: url(https://l8studio.com.au/img/blurs/Blur-03.png) no-repeat center center;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.blur-grey {
    /*background: url('http://l8studio.app:8000/img/blurs/Blur-07.jpg') no-repeat center center;*/
    background: url(https://l8studio.com.au/img/blurs/Blur-07.jpg) no-repeat center center;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.blur6 {
    /*background: url('http://l8studio.app:8000/img/blurs/Blur-6.jpg') no-repeat center center;*/
    /*background: url('http://l8studio.com.au/img/blurs/Blur-06.jpg') no-repeat center center;*/
    background: url(https://l8studio.com.au/img/blurs/Blur-06.jpg) no-repeat center center;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.blur7 {
    /*background: url('http://l8studio.app:8000/img/blurs/Blur-6.jpg') no-repeat center center;*/
    /*background: url('http://l8studio.com.au/img/blurs/Blur-06.jpg') no-repeat center center;*/
    background: url(https://l8studio.com.au/img/blurs/Blur-07.jpg) no-repeat center center;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.blur8 {
    /*background: url('http://l8studio.app:8000/img/blurs/Blur-6.jpg') no-repeat center center;*/
    /*background: url('http://l8studio.com.au/img/blurs/Blur-06.jpg') no-repeat center center;*/
    background: url(https://l8studio.com.au/img/blurs/Blur-08.jpg) no-repeat center center;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.blur9 {
    /*background: url('http://l8studio.app:8000/img/blurs/Blur-6.jpg') no-repeat center center;*/
    /*background: url('http://l8studio.com.au/img/blurs/Blur-06.jpg') no-repeat center center;*/
    background: url(https://l8studio.com.au/img/blurs/Blur-09.jpg) no-repeat center center;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.blur10 {
    /*background: url('http://l8studio.app:8000/img/blurs/Blur-10.jpg') no-repeat center center;*/
    /*background: url('http://l8studio.com.au/img/blurs/Blur-10.jpg') no-repeat center center;*/
    background: url(https://l8studio.com.au/img/blurs/Blur-10.jpg) no-repeat center center;
    position: relative;
    content: "";
    filter: alpha(opacity=40);
    /*IE*/
    -moz-opacity-: 0.40;
    /*Mozilla*/
    opacity: 0.40;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.z-index0 {z-index: 0;}
.z-index100 {z-index: 100;}
.z-index200 {z-index: 200;}
.z-index300 {z-index: 300;}
.z-index1030 {z-index: 1030;}

.z-index-minus100 {z-index: -100;}

.alert {
    padding: 2px 0;
    margin-bottom: 0;
}

.fixed { position: fixed;}
.relative { position: relative;}
.absolute { position: absolute;}
/*.opacity1 {*/
    /*filter: alpha(opacity=100);*/
    /*IE*/
    /*-moz-opacity-: 1.0;*/
    /*Mozilla*/
    /*opacity: 1.0;*/
/*}*/

.ls5 {letter-spacing: 5px;}
.ls7 {letter-spacing: 7px;}
.ls10 {letter-spacing: 10px;}
.ls12 {letter-spacing: 12px;}
.ls15 {letter-spacing: 15px;}
.ls17 {letter-spacing: 17px;}
.ls20 {letter-spacing: 20px;}

.h-align {
    text-align: -moz-center;
    text-align: -webkit-center;
    text-align: -ms-center;
    text-align: -o-center;
}

.v-align {
    position: relative;
    top: 50%;
    left: auto;
    /* prefixes */
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.vh-align {
    position: relative;
    top: 50%;
    left: 50%;
    /* prefixes */
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.has-error .form-control-feedback {
    color: #E74C3C;
}
.has-success .form-control-feedback {
    color: #18BCA0;
}

.content-top-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.content-top-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.content-top-70 {
    margin-top: 70px;
    margin-bottom: 70px;
}

.content-top-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

.top5 { margin-top:5px; }
.top7 { margin-top:7px; }
.top10 { margin-top:10px; }
.top15 { margin-top:15px; }
.top17 { margin-top:17px; }
.top20 { margin-top:20px; }
.top25 { margin-top:25px; }
.top30 { margin-top:30px; }
.top35 { margin-top:35px; }

.medium {
    font-size: 16px;
}

.height50  { height: 50px; }
.height100  { height: 100px; }
.height200  { height: 200px; }
.height300  { height: 300px; }
.height350  { height: 350px; }
.height373  { height: 373px; }
.height375  { height: 375px; }
.height400  { height: 400px; }
.height460  { height: 460px; }
.height500  { height: 500px; }
.height600  { height: 600px; }
.height700  { height: 700px; }
.height800  { height: 800px; }
.height812  { height: 812px; }
.height850  { height: 850px; }
.height874  { height: 874px; }
.height895  { height: 895px; }
.height900  { height: 900px; }
.height905  { height: 905px; }
.height955  { height: 955px; }

.voffset2  { margin-top: 2px; }
.voffset5 { margin-top: 5px; }
.voffset10 { margin-top: 10px; }
.voffset15 { margin-top: 15px; }
.voffset30 { margin-top: 30px; }
.voffset40 { margin-top: 40px; }
.voffset50 { margin-top: 50px; }
.voffset60 { margin-top: 60px; }
.voffset70 { margin-top: 70px; }
.voffset80 { margin-top: 80px; }
.voffset90 { margin-top: 90px; }
.voffset100 { margin-top: 100px; }
.voffset150 { margin-top: 150px; }

.pad2  { padding-top: 2px; }
.pad5 { padding-top: 5px; }
.pad10 { padding-top: 10px; }
.pad15 { padding-top: 15px; }
.pad30 { padding-top: 30px; }
.pad40 { padding-top: 40px; }
.pad50 { padding-top: 50px; }
.pad60 { padding-top: 60px; }
.pad70 { padding-top: 70px; }
.pad80 { padding-top: 80px; }
.pad90 { padding-top: 90px; }
.pad100 { padding-top: 100px; }
.pad120 { padding-top: 120px; }
.pad150 { padding-top: 150px; }

.padtb20 { padding-top: 20px; padding-bottom: 20px; }
.padtb30 { padding-top: 30px; padding-bottom: 30px; }
.padtb40 { padding-top: 40px; padding-bottom: 40px; }
.padtb50 { padding-top: 50px; padding-bottom: 50px; }

.bg-inherit {
    background: inherit;
    border: none;
}
.bg-white {
    color: #000000;
    background: #FFFFFF;
    /*background: #F5F6F6;*/
    text-shadow: none;
}

.bg-c8 {
    color: inherit;
    background: #C2C0C0;
    text-shadow: none;
}
.bg-offwhite {
    color: #363636;
    background: #C9C9C9;
    background: #EAEAEA;
    /*background: #E3E3E3 ;*/
    /*background: rgba(201, 201, 201, 0.91) none repeat scroll 0% 0%;*/
    text-shadow: none;
}

.bg-whitelight {
    color: #2d2d2d;
    background: #DFDFDF;
    /*background: #EAEAEA;*/
    /*background: #E3E3E3 ;*/
    /*background: rgba(201, 201, 201, 0.91) none repeat scroll 0% 0%;*/
    text-shadow: none;
}

.bg-black {
    background: #000000;
    color: #DDDDDD;
}

.bg-blue-grey {
    background: #2C3E50;
    color: #DDDDDD;
}

.bg-slate {
    background: #272B30;
    color: #C8C8C8;
}

.border-top-white {
    border-top: 3px solid #f4f4f4;
}

.border-black {
     border: 1px solid #000000;
}

.bg-purple {
    background: #553B80;
    color: #fff;
}

.bg-grey {
    background: #2A2A2A;
    color: #FFFFFFff;
}


/*custom color classes*/

.text-gold {
    /*color: #DAA520;*/
    color: #AE8913;
    /*color: #d4af37;*/
    /*color: #C5B358;*/
    /*color: #F0DB3C;*/
}

.text-black {
    color: #000000;
}
.text-white {
    color: #ffffff;
}

.text-grey {
    /*color: #3E3E3E;*/
    color: #485563;
}

.text-slate {
    color: #272B30;
    text-shadow: none;
}

.text-black-shadow {
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
}
.text-white-shadow {
    text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.4);
}
.box-shadow {
    /*box-shadow: 1px 1px 10px #6E6E6E;*/
    box-shadow: 0px 0px 10px #091017;
}

.facebook
{
    /*border-radius: 10px;*/
}

.facebook:hover {
    color: #3D5B99;
    /*border-bottom: 1px solid #3D5B99;*/
    /*-webkit-transform: scale(1.5) rotate(360deg);*/
    /*-moz-transform: scale(1.5) rotate(360deg);*/
    /*-ms-transform: scale(1.5) rotate(360deg);*/
    /*-o-transform: scale(1.5) rotate(360deg);*/
    /*transform: scale(1.5) rotate(360deg);*/
    /*-webkit-transition: all 0.5s ease-in-out;*/
    /*-moz-transition: all 0.5s ease-in-out;*/
    /*-ms-transition: all 0.5s ease-in-out;*/
    /*-o-transition: all 0.5s ease-in-out;*/
    /*transition: all 0.5s ease-in-out;*/
}
.twitter:hover {
    color: #25C3DA;
}
.houzz:hover {
    color: #7AC143;
}
.google-plus:hover {
    color: #B83334;
}
.linkedin:hover {
    color: #007AB9;
}
.youtube:hover {
    color: #E02927;
}
.instagram:hover {
    color: #984C01;
}



/*overwrite settings for select2 jquery plugin*/
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #DF691A;
    color: #000000;
    border: 1px solid #DF691A;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #000000;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #5897fb;
    color: #000000;
}

* .selection {
    color: #000000;
}

.select2-container {
    color: rgb(0, 0, 0);
    font-weight: 500;
}

.btn-black {
    border: none;
    color: #f4f4f4;
    background-color: #000000;
    transition: all 0.1s ease linear;
    -moz-transition: all 0.1s ease linear;
    -webkit-trasition: all 0.1s ease linear;
    -o-trasition: all 0.1s ease linear;
    -ms-trasition: all 0.1s ease linear;
}

.btn-black:hover, .btn-black:focus {
    text-decoration: none;
    color: #000000;
    background-color: #f4f4f4;
    border: none;
    transition: all 0.1s ease linear;
    -moz-transition: all 0.1s ease linear;
    -webkit-trasition: all 0.1s ease linear;
    -o-trasition: all 0.1s ease linear;
    -ms-trasition: all 0.1s ease linear;
}

.btn-white {
    /*border: 1px solid #000000;*/
    border: none;
    color: #000000;
    /*font-weight: 500;*/
    background-color: #f4f4f4;
    /*border-radius: 0;*/
    /*border-color: transparent;*/
    /*box-shadow: inset 0 0 0 0 #c1557a;*/
    /*box-shadow: inset 0 0 0 0 #DF691A;*/
    transition: all 0.1s ease linear;
    -moz-transition: all 0.1s ease linear;
    -webkit-trasition: all 0.1s ease linear;
    -o-trasition: all 0.1s ease linear;
    -ms-trasition: all 0.1s ease linear;
}

.btn-white:hover, .btn-white:focus {
    text-decoration: none;
    color: #f4f4f4;
    background-color: #000000;
    /*border-radius: 0;*/
    /*border-color: transparent;*/
    border: none;
    transition: all 0.1s ease linear;
    -moz-transition: all 0.1s ease linear;
    -webkit-trasition: all 0.1s ease linear;
    -o-trasition: all 0.1s ease linear;
    -ms-trasition: all 0.1s ease linear;
    /*-webkit-transform: translate(0,10px);*/
    /*-moz-transform: translate(0,10px);*/
    /*-o-transform: translate(0,10px);*/
    /*-ms-transform: translate(0,10px);*/
    /*transform: translate(0,10px);*/
    /*box-shadow: inset 0 100px 0 0 #c1557a;*/
    /*box-shadow: inset 0 100px 0 0 #DF691A;*/
    /*box-shadow: inset 0 100px 0 0 #DF691A;*/
}

.circle {
    border-radius: 50%;
    -moz-border-radius: 50%;
    position: relative;
    overflow: hidden;
}

.fade
{
    opacity:0.5;
}
.fade:hover
{
    opacity:1;
}

.grow:hover
{
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
}

.shrink:hover
{
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
}

.rotate:hover
{
    -webkit-transform: rotateZ(-30deg);
    -ms-transform: rotateZ(-30deg);
    transform: rotateZ(-30deg);
}

.color:hover
{
    background:#53a7ea;
}

ul.social-buttons {
    margin-bottom: 0px;
}

hr {
    border-color: #f4f4f4;
}

/*.panel {*/
    /*position: relative;*/
    /*background-color: rgba(61, 61, 61, 0.5);*/
    /*background-color: #2C3E50;*/
    /*box-shadow: none;*/
    /*border: 2px solid #4E5D6C;*/
    /*border-radius: 10px;*/
    /*border-left: 5px solid #DF691A;*/
    /*border-right: 5px solid #DF691A;*/
    /*z-index:1028;*/
/*}*/

/*.panel-default > .panel-heading {*/
    /*background-color: rgba(0, 0, 0, 0.47);*/
    /*color: #EBEBEB;*/
/*}*/

/*#ourservices .panel .panel-body {*/
    /*height: 375px;*/
/*}*/

#ourservices .panel:hover {
    /*-ms-transform: translate(0,-5px); *//* IE 9 */
    /*-webkit-transform: translate(0,-5px); *//* Safari */
    /*transform: translate(0,-5px);*/
    -webkit-transform: scale(1.025);
    -moz-transform: scale(1.025);
    -ms-transform: scale(1.025);
    -o-transform: scale(1.025);
    transform: scale(1.025);
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -ms-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
}

.affix {
    position: fixed;
    z-index: 1029;
    border:none;
    width:100%;
    background: -webkit-linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.99));
    background: -o-linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.99));
    background: -moz-linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.99));
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.99));
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
}

.affix + .container {
    padding-top:95px;
}

.small {
    font-size: 75%;
}

#header > .row > .nav {
    padding-left: 100px;
}

/*showcase*/

#showcase .view .mask, #showcase .view .content {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0
}
/*.view img {*/
    /*display: block;*/
    /*position: relative*/
/*}*/
#showcase .view h2 {
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    position: relative;
    font-size: 17px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.8);
    margin: 20px 0 0 0
}
#showcase .view p {
    font-family: Georgia, serif;
    font-style: italic;
    font-size: 12px;
    position: relative;
    color: #fff;
    padding: 10px 20px 20px;
    text-align: center
}
#showcase .view a.info {
    display: inline-block;
    text-decoration: none;
    padding: 7px 14px;
    background: transparent;
    color: #fff;
    text-transform: uppercase;
    /*box-shadow: 0 0 1px #000;*/
}
#showcase .view a.info:hover {
    /*box-shadow: 0 0 5px #000;*/
    background: transparent;
}

.view-first img {
    transition: all 0.2s linear;
}
.view-first .mask {
    opacity: 0;
    background-color: rgba(94, 172, 219, 0.70);
    transition: all 0.4s ease-in-out;
}
.view-first h2 {
    transform: translateY(-100px);
    opacity: 0;
    transition: all 0.2s ease-in-out;
}
.view-first p {
    transform: translateY(100px);
    opacity: 0;
	transition: all 0.2s linear;
}
.view-first a.info{
    opacity: 0;
	transition: all 0.2s ease-in-out;
}

.view-first:hover img {
	/*transform: scale(1.1);*/
    /*-ms-transform: scale(1.1);*/
    /*-moz-transform: scale(1.1);*/
}
.view-first:hover .mask {
	opacity: 1;
}
.view-first:hover h2,
.view-first:hover p,
.view-first:hover a.info {
    opacity: 1;
    transform: translateY(0px);
}
.view-first:hover p {
    transition-delay: 0.1s;
}
.view-first:hover a.info {
    transition-delay: 0.2s;
}

.view-ninth .mask-1,
.view-ninth .mask-2{
	background-color: rgba(0,0,0,0.5);
    height: 100%;
    width: 100%;
	background: rgba(119,0,36,0.5);
	opacity: 1;
    transition: all 0.3s ease-in-out 0.6s;
}
.view-ninth .mask-1 {
    left: auto;
    right: 0px;
    transform: rotate(56.5deg) translateX(-50%);
    transform-origin: 100% 0%;
}
.view-ninth .mask-2 {
    top: auto;
    bottom: 0px;
    transform: rotate(56.5deg) translateX(50%);
    transform-origin: 0% 100%;
}

.view-ninth .content{
    background: rgba(0,0,0,0.9);
    height: 0px;
    opacity: 0.5;
    width: 100%;
    overflow: hidden;
    transform: rotate(-33.5deg) translate(-112px,166px);
    transform-origin: 0% 100%;
    transition: all 0.4s ease-in-out 0.3s;
}
.view-ninth h2{
    background: transparent;
    margin-top: 5px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
}
.view-ninth a.info{
    display: none;
}

.view-ninth:hover .content{
    height: 100%;
    width: 100%;
    opacity: 0.9;
    top: 40px;
    transform: rotate(0deg) translate(0px,0px);
}
.view-ninth:hover .mask-1,
.view-ninth:hover .mask-2{
	transition-delay: 0s;
}
.view-ninth:hover .mask-1{
    transform: rotate(56.5deg) translateX(1px);
}
.view-ninth:hover .mask-2 {
    transform: rotate(56.5deg) translateX(-1px);
}


#showcase .col-lg-3 {
    padding: 0;
}

#showcase img {
    /*filter: alpha(opacity=40);*/
    /*IE*/
    /*-moz-opacity-: 0.4;*/
    /*Mozilla*/
    /*opacity: 0.4;*/
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-trasition: all 0.3s ease;
    -o-trasition: all 0.3s ease;
    -ms-trasition: all 0.3s ease;
}

/*#showcase img:hover {*/
    /*filter: alpha(opacity=100);*/
    /*IE*/
    /*-moz-opacity-: 1.0;*/
    /*Mozilla*/
    /*opacity: 1.0;*/
    /*width: 100%;*/
    /*height: 100%;*/
    /*box-shadow: inset 0 100px 0 0 #c1557a;*/
    /*box-shadow: inset 0 0 10px 0 #ccc;*/
    /*-moz-box-shadow: inset 0 0 10px 0 #ccc;*/
    /*-webkit-box-shadow: inset 0 0 10px 0 #ccc;*/
    /*transition: all 0.3s ease;*/
    /*-moz-transition: all 0.3s ease;*/
    /*-webkit-trasition: all 0.3s ease;*/
    /*-o-trasition: all 0.3s ease;*/
    /*-ms-trasition: all 0.3s ease;*/
/*}*/

#portfolio .col-lg-4 {
    padding: 2px;
}

#portfolio img {
    filter: grayscale(100%);
    /*IE*/
    -moz-grayscale-: 1.0;
    /*Mozilla*/
    grayscale: 1.0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-trasition: all 0.3s ease;
    -o-trasition: all 0.3s ease;
    -ms-trasition: all 0.3s ease;
}

#portfolio img:hover {
    filter: grayscale(0);
    /*IE*/
    -moz-gryscale-: 0;
    /*Mozilla*/
    grayscale: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 100px 0 0 #c1557a;
    box-shadow: inset 0 0 10px 0 #ccc;
    -moz-box-shadow: inset 0 0 10px 0 #ccc;
    -webkit-box-shadow: inset 0 0 10px 0 #ccc;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-trasition: all 0.3s ease;
    -o-trasition: all 0.3s ease;
    -ms-trasition: all 0.3s ease;
}

#portfolio3 .group1 img {
    filter: grayscale(100%);
    /*IE*/
    -moz-grayscale-: 1.0;
    /*Mozilla*/
    grayscale: 1.0;
}

#portfolio3 img {
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-trasition: all 0.3s ease;
    -o-trasition: all 0.3s ease;
    -ms-trasition: all 0.3s ease;
}

#portfolio3 .group1 img:hover {
    filter: grayscale(0);
    /*IE*/
    -moz-gryscale-: 0;
    /*Mozilla*/
    grayscale: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 100px 0 0 #c1557a;
    box-shadow: inset 0 0 10px 0 #ccc;
    -moz-box-shadow: inset 0 0 10px 0 #ccc;
    -webkit-box-shadow: inset 0 0 10px 0 #ccc;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-trasition: all 0.3s ease;
    -o-trasition: all 0.3s ease;
    -ms-trasition: all 0.3s ease;
}

#portfolio3 .col-lg-4 {
    height: 125px;
    padding: 2px;
}

#portfolio4 .group1 img {
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-trasition: all 0.3s ease;
    -o-trasition: all 0.3s ease;
    -ms-trasition: all 0.3s ease;
}

#portfolio4 .group1 img:hover {
    filter: grayscale(0);
    /*IE*/
    -moz-gryscale-: 0;
    /*Mozilla*/
    grayscale: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 100px 0 0 #c1557a;
    box-shadow: inset 0 0 10px 0 #ccc;
    -moz-box-shadow: inset 0 0 10px 0 #ccc;
    -webkit-box-shadow: inset 0 0 10px 0 #ccc;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-trasition: all 0.3s ease;
    -o-trasition: all 0.3s ease;
    -ms-trasition: all 0.3s ease;
}

#clients img {
    filter: grayscale(100%);
    /*IE*/
    -moz-grayscale-: 1.0;
    /*Mozilla*/
    grayscale: 1.0;
    width: 100%;
    height: 100%;
    transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -webkit-trasition: all 0.1s ease;
    -o-trasition: all 0.1s ease;
    -ms-trasition: all 0.1s ease;
}

#clients img:hover {
    filter: grayscale(0);
    /*IE*/
    -moz-grayscale-: 0;
    /*Mozilla*/
    grayscale: 0;
}

/*enquire*/
#success {
    min-height: 220px;
    height:100%;
}

#success > .alert > .small {
    font-size: 85%;
}

.form-control {
    border-radius: 0;
}

#quoteLines .jumbotron .fa {
    font-size: 15px;
}

#about > .container-fluid > .row > .col-lg-12 > .jumbotron {
        padding-top: 0;
        /*padding-bottom: 0;*/
}

#about > .container-fluid > .row > .col-md-12 > .jumbotron {
        padding-top: 0;
        /*padding-bottom: 0;*/
}

/*footer*/

#footer > .navbar-default, #footer > .navbar-inverse {
    /*background: #232628;*/
    /*background-color: #2B3E50;*/
    background-color: rgba(0, 0, 0, 0.85);
    font-weight: 100;
    border: none;
    background-image: none;
    box-shadow: none;
    color: rgb(118, 129, 141);
    /*color: #838383;*/
}

#footer .navbar-default a:focus, #footer .navbar-default a:hover,
#footer .navbar-inverse a:focus, #footer .navbar-inverse a:hover {
    color: #edeae5;
    text-decoration: underline;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -ms-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
}

#footer > .navbar {
    /*max-height:80px;*/
    /*height: 150px;*/
    border-radius: 0;
    margin-bottom: 0;
}

#footer > .navbar > .container > .page-header {
    border-bottom: 1px solid #768082;
    margin-top: 20px;
}

#footer ul li {
    /*padding: 2px 0;*/
    padding: 2px 5px;
}

#footer .list-inline {
    margin-left: 0;
}

#newsletter .btn {
    margin-top: 10px;
}

@media (min-width: 992px) {
     #newsletter .form-control {
     /*width: auto;*/
     /*width: 100%;*/
    }
}

.fa-heart {
    /*color:#DB59E3;*/
    color:#F000FF;
    /*color:#FFCF00;*/
    /*color:#C2B23E;*/
    /*color:#F89406;*/
    /*color:#d4af37;*/
    /*color:#C5B358;*/
    /*color:#DAA520;*/
    /*color:#AE8913;*/
    padding-left:0px;
}

.scroll-x {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
}

.scroll-y {
    overflow-y: scroll;
    overflow-x: hidden;
}

.ul-block {
    text-align: left;
    text-decoration: none;
    list-style: none;
}

/* promo */

.promo {
    z-index: 1049;
    position: fixed;
    right: 2%;
    bottom: 2%;
    width: 100px;
    height: auto;
}

/*scroll top button class*/

.scroll-top {
    z-index: 1049;
    position: fixed;
    right: 1%;
    bottom: 1%;
    width: 40px;
    height: 40px;
}

.scroll-top .btn {
    width: 30px;
    height: 30px;
    /*padding: 6px 4px;*/
    padding: 4px 3px;
}

.scroll-top .btn:focus {
    outline: 0;
}

.btn-scrolltop, .btn-scrolltop:focus, .btn-scrolltop:active {
    /*background-color: #EAEAEA;*/
    background-color: #DF691A;
    /*background-color: #df002a;*/
    color:#000000;
    transition : all 0.3s ease 0s;
    border: none;
}

#scroll-top-button > .scroll-top > .btn > .fa {
    padding-left: 5px;
}

.btn-scrolltop:hover {
    background-color: #000000;
    /*#c54e4e #90186e #38A08D #e13243;*/
    color:#ffffff;
    transition : all 0.3s ease 0s;
    border: none;
}

/*.btn {*/
    /*display: inline;*/
/*}*/

.btn:focus,
.btn:active,
.btn.active {
    outline: 0;
}

.navbar a:focus {
    outline: 0;
}

.navbar .navbar-nav li a:focus {
    outline: 0;
}

.btn-custom {
    background-color: hsl(38, 100%, 36%) !important;
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffc665", endColorstr="#b77400");
    background-image: -khtml-gradient(linear, left top, left bottom, from(#ffc665), to(#b77400));
    background-image: -moz-linear-gradient(top, #ffc665, #b77400);
    background-image: -ms-linear-gradient(top, #ffc665, #b77400);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffc665), color-stop(100%, #b77400));
    background-image: -webkit-linear-gradient(top, #ffc665, #b77400);
    background-image: -o-linear-gradient(top, #ffc665, #b77400);
    background-image: linear-gradient(#ffc665, #b77400);
    border-color: #b77400 #b77400 hsl(38, 100%, 27.5%);
    color: #333 !important;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.56);
    -webkit-font-smoothing: antialiased;
}

.thumbnail {
    display: inline;
    padding: 0;
    margin-bottom: 0;
    line-height: inherit;
    background-color: none;
    border: none;
    border-radius: 0;
}

body, body.modal {
    padding-right: 0 !important;
}

body.modal {
    overflow-y: hidden;
}

.center {
    position: absolute;
    left: 50%;
    top: 50%;

    /*
    Nope =(
    margin-left: -25%;
    margin-top: -25%;
    */

    /*
    Yep!
    */
    transform: translate(-50%, -50%);

    /*
    Not even necessary really.
    e.g. Height could be left out!
    */
    width: 40%;
    height: 50%;
}

#colorbox {
    margin-top: 70px;
}

#cboxTitle{color:#000000;}
#cboxCurrent{color:#000000;}

label {
    font-weight: inherit;
}

.team-member {
    text-align: center;
    margin-bottom: 50px;
}

.team-member img {
    margin: 0px auto;
    /*border: 2px solid #FFF;*/
    border: 1px solid #FFF;
}

ul.social-buttons li a {
    display: block;
    /*background-color: #222;*/
    background-color: #EAEAEA;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    font-size: 20px;
    line-height: 40px;
    /*color: #FFF;*/
    color: #000000;
    outline: 0px none;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -ms-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
}

ul.social-buttons li a:hover, ul.social-buttons li a:focus, ul.social-buttons li a:active {
    /*background-color: #DF691A;*/
    background-color: #000000;
    color: #EAEAEA;
}

.blur-bg {
    background: #transparent linear-gradient(135deg, #DC2424 0%, #350060 100%) repeat scroll 0% 0%;
}


/*@-moz-document url-prefix() {*/
    /*fieldset { display: table-cell; }*/
/*}*/